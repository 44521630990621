.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;
  td {
    padding: 10px 0;
  }
}

.clickable-row {
  background-color: #d7f6ff;
}

.clickable-row:nth-child(even) {
  background-color: #c6f3ff;
}

.clickable-row:hover {
  cursor: pointer;
  background-color: aliceblue;
  padding: 12px;
  margin: 12px;
}

.loader {
  margin-top: 50px;
}

.pag-button {
  color: black;
  &.selected {
    background-color: #929292;
  }
  
}

.product-list {
  font-size: 16px;
  text-align: center;
  margin:0 auto;
  padding-left: 100px;
  border-width: 2px;
  border: #282c34;
  max-width: 600px;
  @media (max-width: 850px) {
    width: 80%;
  }
  thead tr th {
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 30px;
    padding-left: 30px;
    &:first-child {
      text-align: left;
      padding-left: 0;
    }
  }
  tr td:first-child {
    text-align: left;
  }

}

.product-col {
  width: 100%;
}

.form-results {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  tr td:first-child {
    font-weight: 800;
  }
  h4, p{
    margin: 0;
  }
}

.hide {
  display: none;
}

input[type=text], select {
  width: 300px;
  padding: 12px 20px;
  margin: 8px auto;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.status-form {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.back-button {
  text-align: left;
  padding: 10px;
  button {
    font-weight: 600;
    font-size: larger;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.money {
 min-width: 250px;
}

header {
  text-align: left;
  display: flex;
  padding: 10px;
  .logo {
    padding: 10px;
  }
  .logout-button {
    margin-left: auto;
    padding: 10px;
  }
}

.addresses {
  width: 100%;
  display: flex;
  justify-content: center;
  .address {
    text-align: left;
    margin: 0 25px;
  }
}

.totals {
  margin: 0 auto;
  background-color: #f8f8f8;
  max-width: 700px;
  display: flex;
  justify-content: end;
  .lists{
    margin: 5px 15px;
    font-size: smaller;
    text-align: right;
  }
  .label {
    color: rgb(141, 141, 141);
  }
  .payment-method {
    margin-right: auto;
    margin-left: 25px;
    * {
      padding: 0;
      margin: 5px;
    }
    p {
      font-size: small;
    }
  }
}



.message {
  h5 {
    font-weight: 700;
  }
  .error{
    color: rgb(137, 0, 0);
  }
  .success{
    color: rgb(15, 110, 1);
  }
}

.coupons-container {
  display: flex;
  width: 100%;
  .coupons {
    margin: 0 auto;
    display: flex;
    align-items: center;
    :first-child {
      margin-right: 5px;
    }
  }

}